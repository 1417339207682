import React from "react";
import '../styles/footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-header">
          <a href="/" className="logo footer"><img src="/image/log.svg" alt="Logo" /></a>
        </div>
        <div className="footer-info">
          <div className="social-medias">
            <a href="mailto:makswarpten@mail.ru" target="_blank" rel="noopener noreferrer"><i className='bx bx-envelope'></i></a>
            <a href="https://t.me/Snamik" target="_blank" rel="noopener noreferrer"><i className='bx bxl-telegram'></i></a>
            <a href="https://github.com/SnamikMT" target="_blank" rel="noopener noreferrer"><i className='bx bxl-github'></i></a>
            <a href="https://www.instagram.com/snamik_?igsh=cW9zdW93dG9va2ts" target="_blank" rel="noopener noreferrer"><i className='bx bxl-instagram'></i></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
