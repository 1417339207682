import React, { useState } from 'react';
import '../styles/ContactSection.css';

const ContactSection = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [statusType, setStatusType] = useState(''); // success, error, warning

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !message) {
      setStatus('Пожалуйста, заполните все поля.');
      setStatusType('warning');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/submit-new-form`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, email, message }),
      });

      if (response.ok) {
        setStatus('Сообщение успешно отправлено!');
        setStatusType('success');
        setName('');
        setEmail('');
        setMessage('');
      } else {
        setStatus('Ошибка при отправке сообщения. Попробуйте позже.');
        setStatusType('error');
      }
    } catch (error) {
      console.error('Ошибка при отправке формы:', error);
      setStatus('Ошибка сети. Попробуйте позже.');
      setStatusType('error');
    }
  };

  return (
    <section className="contact-section" id="order" data-section="order">
      <h1>Обсудим ваш проект?</h1>
      <div className="starline duoline">
        <img src="/image/rightline.png" alt="Изогнутая" className="right-line lines lots" />
        <img src="/image/starline.png" alt="Изогнутая" id="star2" className="right-line lot" />
      </div>
      <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="form-container">
          <input
            type="text"
            placeholder="Имя Фамилия"
            value={name}
            className="custom-placeholder"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            placeholder="Электронный Адрес"
            value={email}
            className="custom-placeholder" 
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="textarea-container">
          <textarea
            placeholder="Ваше Сообщение"
            value={message}
            className="custom-placeholder"
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <div className="submit-container">
            <button className="submit-button" type="submit">
              Отправить
            </button>
          </div>
          <div className="starline duoline2">
            <img src="/image/rightstar1.png" alt="Изогнутая" id="star2" className="right-line lot2" />
            <img src="/image/rightline2.png" alt="Изогнутая" className="right-line lines lots2" />
          </div>
        </div>
      </form>

        {status && (
          <p className={`status-message ${statusType}`}>
            {status}
          </p>
        )}
      </div>
    </section>
  );
};

export default ContactSection;
