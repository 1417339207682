import React, { useEffect, useRef } from 'react';
import baffle from 'baffle';

const MatrixText = ({ text }) => {
  const textRef = useRef(null);

  useEffect(() => {
    const baffleEffect = baffle(textRef.current, {
      characters: '█▓▒░█▓▒░<>/░▒▓',
      speed: 100,
    });

    // Функция для запуска анимации при появлении элемента на экране
    const handleScroll = () => {
      const rect = textRef.current.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
      
      if (isVisible) {
        baffleEffect.start();
        baffleEffect.reveal(2000); // Длительность анимации
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return <h2 ref={textRef}>{text}</h2>;
};

export default MatrixText;
