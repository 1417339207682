import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import Portfolio from "./components/Portfolio";
import Skills from "./components/Skills";
import Contacts from "./components/Contacts";
import ProjectDetails from "./components/ProjectDetails";
import AdminLogin from "./components/AdminLogin";
import AdminDashboard from "./components/AdminDashboard";
import Designer from "./components/Designer";
import DesignerDetails from "./components/DesignerDetails";
import NotFound from "./components/NotFound";
import './styles/App.css';

function App() {
  const isAuthenticated = !!localStorage.getItem('authToken');

  return (
    <Router>
      <Routes>
        {/* Main route */}
        <Route path="/" element={<MainRoutes />} />
        <Route path="/designer" element={<Designer />} />
        <Route path="/designer/:slug" element={<DesignerDetails />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={
          isAuthenticated ? <AdminDashboard /> : <Navigate to="/admin" />
        } />
        <Route path="/project/:slug" element={<ProjectDetails />} />

        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

function MainRoutes() {
  const location = useLocation();
  const isDesignerRoute = location.pathname.startsWith("/designer");

  return (
    <>
      {/* Conditionally render Header if not on the designer routes */}
      {!isDesignerRoute && <Header />}
      
      <div>
        <Home />
        <Skills />
        <Portfolio />
      </div>
      
      {/* Conditionally render Contacts (footer) if not on the designer routes */}
      {!isDesignerRoute && <Contacts />}
    </>
  );
}

export default App;
