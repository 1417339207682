import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/AdminLogin.css";

function AdminLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/admin/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();
    if (response.ok) {
      localStorage.setItem("isAdmin", "true"); // Сохраняем статус авторизации
      localStorage.setItem("username", username); // Сохраняем имя пользователя
      navigate("/admin/dashboard");
    } else {
      setError(data.message || "Ошибка авторизации");
    }
  };

  return (
    <div className="admin-login-container">
      <h2 className="admin-login-title">Вход для администратора</h2>
      {error && <p className="admin-login-error">{error}</p>}
      <form className="admin-login-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-label">Логин:</label>
          <input
            type="text"
            className="form-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label">Пароль:</label>
          <input
            type="password"
            className="form-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button className="form-button" type="submit">
          Войти
        </button>
      </form>
    </div>
  );
}

export default AdminLogin;
