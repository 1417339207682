import React, { useEffect, useState } from "react";
import "../styles/Designer.css";
import { Link } from "react-router-dom";
import DesignerHeader from './DesignerHeader';
import ContactSection from './ContactSection';
import 'intersection-observer';

const categories = ["Все", "Фирменный стиль", "Дизайн полиграфии", "Дизайн многостраничных изданий", "Event дизайн"];
const toolsList = [
  { name: "Photoshop", icon: "/image/photoshop-icon.png" },
  { name: "Illustrator", icon: "/image/illustrator-icon.png" },
  { name: "CorelDraw", icon: "/image/coreldraw-icon.png" },
  { name: "Figma", icon: "/image/figma-icon.png" },
  { name: "Adobe Premiere", icon: "/image/premiere-icon.png" },
  { name: "Final Cut", icon: "/image/finalcut-icon.png" },
];

const Designer = () => {
  const [projects, setProjects] = useState([]);
  const [activeCategory, setActiveCategory] = useState("Все");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [newProject, setNewProject] = useState({
    title: "",
    description: "",
    categories: [],
    tools: [],
    image: null,
  });
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
      const adminStatus = localStorage.getItem('isAdmin') === 'true';
      setIsAdmin(adminStatus);
  }, []);

  useEffect(() => {
    fetchProjectsFromServer();
  }, []);

  useEffect(() => {
    const progressBars = document.querySelectorAll('.progress-bar-fill');
    const skillsSection = document.getElementById('skills-section');
  
    function animateProgressBar() {
      progressBars.forEach(bar => {
        const percentage = bar.getAttribute('data-percentage');
        bar.style.width = `${percentage}%`; // Заполняем шкалу до указанного процента
      });
    }
  
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            animateProgressBar(); // Запускаем анимацию
            observer.unobserve(entry.target); // Прекращаем наблюдение
          }
        });
      },
      { threshold: 0.5 } // 50% видимости
    );
  
    if (skillsSection) {
      observer.observe(skillsSection);
    }
  
    // Очистка после размонтирования
    return () => {
      observer.disconnect();
    };
  }, []);
  

  
  const fetchProjectsFromServer = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/designer-projects`);
      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error("Ошибка при загрузке проектов:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append("title", newProject.title);
    formData.append("description", newProject.description);
    formData.append("categories", JSON.stringify(newProject.categories));
    formData.append("tools", JSON.stringify(newProject.tools));
    if (newProject.image) {
      formData.append("image", newProject.image);
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/designer-projects`, {
        method: "POST",
        body: formData,
      });
  
      const data = await response.json();
      if (response.ok) {
        setProjects((prevProjects) => [data, ...prevProjects]);
  
        // Сбрасываем форму
        setNewProject({ title: "", description: "", categories: [], tools: [], image: null });
  
        // Плавное обновление списка проектов
        setSuccessMessage(true);
        setTimeout(() => setSuccessMessage(false), 3000); // Скрываем сообщение через 3 секунды
  
        // Закрываем popup
        setIsPopupOpen(false);
  
        // Просто обнови список проектов через setProjects, без перезагрузки страницы
        setTimeout(() => {
          setIsPopupOpen(false); // Закрытие popup
        }, 500);
  
      } else {
        console.error("Ошибка при добавлении проекта:", data.error || data.message);
      }
  
    } catch (error) {
      console.error("Ошибка при отправке запроса:", error);
    }
  };
  

  const handleCategoryChange = (category) => {
    setNewProject((prevState) => {
      const updatedCategories = prevState.categories.includes(category)
        ? prevState.categories.filter((c) => c !== category)
        : [...prevState.categories, category];
      return { ...prevState, categories: updatedCategories };
    });
  };

  const handleToolsChange = (toolName) => {
    setNewProject((prevState) => {
      const updatedTools = prevState.tools.includes(toolName)
        ? prevState.tools.filter((tool) => tool !== toolName)
        : [...prevState.tools, toolName];
      return { ...prevState, tools: updatedTools };
    });
  };

  const handleFileChange = (e) => {
    setNewProject({ ...newProject, image: e.target.files[0] });
  };

  const handleInputChange = (e) => {
    setNewProject({ ...newProject, [e.target.name]: e.target.value });
  };

  const fetchProjects = (category) => {
    setActiveCategory(category);
  };

  const filteredProjects = projects.filter((project) => {
    const categoryMatch = activeCategory === "Все" || project.categories.includes(activeCategory);
    return categoryMatch;
  });

  
  return (
    <>
    <DesignerHeader />
      <div className="designer-section">
        <div className="background-image">
          <div className="textn">
            <p className="small-text">Привет, я Наталия Чугунова</p>
            <h1 className="large-text">
              ГРАФИЧЕСКИЙ <br /> ДИЗАЙНЕР
            </h1>
          </div>
          <div className="star">
            <img src="/image/StarN.png" alt="Звезда" id="star1" className="zm" />
          </div>
          <div className="zmei1">
            <img src="/image/topzmei.png" alt="Змейка" className="zm" />
          </div>
          <div className="zmei2">
            <img src="/image/zmei2.png" alt="Змейка" className="zm" />
          </div>
          <div className="image-wrapper">
            <img src="/image/naich.png" alt="Designer" className="center-image" />
          </div>
          <h1 className="large-text-overlay">ГРАФИЧЕСКИЙ ДИЗАЙНЕР</h1>
          <div className="social-media">
            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
              <img src="/image/youtube-icon.png" alt="YouTube" className="social-icon" />
            </a>
            <a href="https://t.me/revuls1on" target="_blank" rel="noopener noreferrer">
              <img src="/image/telegram-icon.png" alt="Telegram" className="social-icon" />
            </a>
            <a href="https://www.youtube.com/@rev.ulsion" target="_blank" rel="noopener noreferrer">
              <img src="/image/instagram-icon.png" alt="Instagram" className="social-icon" />
            </a>
          </div>
        </div>
      </div>
      <div className="skills-section" id="skills-section" data-section="skills-section">
        <div className="iam">
          <h1 className="section-title">ЧТО Я УМЕЮ???</h1>
          <img src="/image/iamstar.png" alt="star" id="star2" className="iamstar" />
        </div>

        <p className="section-subtitle">(Мои навыки)</p>
        <div className="skills-container">
          <div className="skill-card">
            <div className="nemperc">
              <p className="skill-name">ВЕБ-ДИЗАЙН</p>
              <p className="skill-percentage">85%</p>
            </div>
            <div className="progress-bar">
              <div className="progress-bar-fill" data-percentage="85">
                <img src="/image/Starprogress.png" alt="Progress Icon" className="progress-icon" />
              </div>
            </div>
          </div>

          <div className="skill-card">
            <div className="nemperc">
              <p className="skill-name">ГРАФИЧЕСКИЙ-ДИЗАЙН</p>
              <p className="skill-percentage">99%</p>
            </div>
            <div className="progress-bar">
              <div className="progress-bar-fill" data-percentage="99">
                <img src="/image/Starprogress.png" alt="Progress Icon" className="progress-icon" />
              </div>
            </div>
          </div>

          <div className="skill-card">
            <div className="nemperc">
              <p className="skill-name">ПРОМЫШЛЕННЫЙ-ДИЗАЙН</p>
              <p className="skill-percentage">90%</p>
            </div>
            <div className="progress-bar">
              <div className="progress-bar-fill" data-percentage="90">
                <img src="/image/Starprogress.png" alt="Progress Icon" className="progress-icon" />
              </div>
            </div>
          </div>
        </div>
    </div>

    <section class="services-section" id="price" data-section="price">
      <div class="service-item">
        <span class="service-number">01</span>
        <h2 class="service-title">Фирменный стиль</h2>
        <span class="service-price">от 15 000 ₽</span>
        <img src="/image/rightstar1.png" alt="Звезда" id="star1" class="service-icon star-icon"/>
      </div>

      <img src="/image/leftline.png" alt="Изогнутая" class="left-line"/>

      <div class="service-item">
        <span class="service-number">02</span>
        <h2 class="service-title">Дизайн <br />полиграфии</h2>
        <span class="service-price">от 2 000 ₽</span>
      </div>

      <div className="starline">
      <img src="/image/starline.png" alt="Изогнутая" id="star2" class="right-line"/>
      <img src="/image/rightline.png" alt="Изогнутая" class="right-line lines"/>
      </div>

      <div class="service-item">
        <span class="service-number">03</span>
        <h2 class="service-title">Дизайн <br /> многостраничных  <br />изданий</h2>
        <span class="service-price">от 10 000 ₽</span>
      </div>

      <img src="/image/leftline.png" alt="Изогнутая" class="left-line"/>

      <div class="service-item">
        <span class="service-number">04</span>
        <h2 class="service-title">Event <br /> дизайн</h2>
        <span class="service-price">от 2 000 ₽</span>
        <img src="/image/rightstar1.png" alt="Звезда" id="star1" class="service-icon star-icon2"/>
      </div>
    </section>


    <div className="designer-portfolio-section" id="portfolio" data-section="portfolio">
      <div className="bestwork">
        <img src="/image/best.png" alt="Звезда" class="best"/>
        <h2 className="designer-portfolio-title">Лучшие Работы</h2>
      </div>
      <p className="myportf">(МОЕ ПОРТФОЛИО)</p>
    
      {isAdmin && (
    <div className="designer-portfolio-add-button" onClick={() => setIsPopupOpen(true)}>
        +
    </div>
      )}

    <div className="designer-portfolio-filters">
        {categories.map((category) => (
            <button
                key={category}
                className={`designer-portfolio-category-btn ${activeCategory === category ? "designer-portfolio-category-btn-active" : ""}`}
                onClick={() => fetchProjects(category)}
            >
                {category}
            </button>
        ))}
    </div>

    <div className="designer-portfolio-grid">
        {filteredProjects.length > 0 ? (
            filteredProjects.map((project) => (
                <Link to={`/designer/${project.slug}`} className="designer-portfolio-item" key={project.id}>
                    <img
                        src={`${process.env.REACT_APP_SERVER_URL}${project.image}`}
                        alt={project.title}
                        className="designer-portfolio-item-image"
                    />
                    <h4 className="designer-portfolio-item-title">{project.title}</h4>
                    <p className="designer-portfolio-item-description">{project.description}</p>
                    
                    {/* Отображаем инструменты как кружки с изображениями */}
                    <div className="designer-portfolio-item-tools">
                        {project.tools && project.tools.length > 0 ? (
                            <div>
                                <h5>ПРОГРАММЫ ИСПОЛЬЗОВАЛИСЬ</h5>
                                <div className="tools-circles">
                                    {project.tools.map((tool, index) => (
                                        <div className="tool-circle" key={index}>
                                            <img 
                                                src={toolsList.find(t => t.name === tool)?.icon || ''}
                                                alt={tool}
                                                className="tool-icon"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <p>Инструменты не указаны</p>
                        )}
                    </div>
                </Link>
            ))
        ) : (
            <p>Проекты не найдены</p>
        )}

      {successMessage && (
        <div className="success-message">
          <p>+ 1 Проект к Нейч успешно</p>
        </div>
      )}

        {isPopupOpen && (
            <>
                <div className="popup-backdrop" onClick={() => setIsPopupOpen(false)}></div>
                <div className="popup2">
                    <form onSubmit={handleSubmit} className="popup-form">
                        <input
                            type="text"
                            name="title"
                            placeholder="Название"
                            value={newProject.title}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="description"
                            placeholder="Описание"
                            value={newProject.description}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="categories">
                            {categories.map((category) => (
                                <label key={category}>
                                    <input
                                        type="checkbox"
                                        checked={newProject.categories.includes(category)}
                                        onChange={() => handleCategoryChange(category)}
                                    />
                                    {category}
                                </label>
                            ))}
                        </div>

                        <div className="tools">
                            {toolsList.map((tool) => (
                                <label key={tool.name}>
                                    <input
                                        type="checkbox"
                                        checked={newProject.tools.includes(tool.name)}
                                        onChange={() => handleToolsChange(tool.name)}
                                    />
                                    <div className="tool-circle">
                                        <img src={tool.icon} alt={tool.name} className="tool-icon" />
                                    </div>
                                    {tool.name}
                                </label>
                            ))}
                        </div>

                        <input type="file" onChange={handleFileChange} />
                        <button type="button" onClick={handleSubmit}>Создать проект</button>
                    </form>
                    <button onClick={() => setIsPopupOpen(false)}>Закрыть</button>
                </div>
            </>
        )}

    </div>
</div>

<ContactSection />

<div className="foot">
  <div className="decorative-line"></div>
  <div className="footerd">© 2024-2025. Все Права Защищены</div>
</div>

    </>
  );
};

export default Designer;
