import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../styles/ProjectDetails.css';
import ImageGallery from 'react-image-gallery';
import MatrixText from "./MatrixText";
import 'react-image-gallery/styles/css/image-gallery.css';

const ProjectDetails = () => {
    const { slug } = useParams();
    const [projectDetails, setProjectDetails] = useState(null);
    const [newImages, setNewImages] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const adminStatus = localStorage.getItem('isAdmin') === 'true';
        setIsAdmin(adminStatus);
    }, []);

    const fetchProjectDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/cards/${slug}`);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();
            setProjectDetails(data);
        } catch (error) {
            console.error('Error fetching project details:', error);
        }
    };

    const fetchNextProject = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/cards/${slug}/next`);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const nextProject = await response.json();
            window.location.href = `/project/${nextProject.slug}`;
        } catch (error) {
            console.error('Error fetching next project:', error);
        }
    };

    const fetchPreviousProject = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/cards/${slug}/previous`);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();
            window.location.href = `/project/${data.slug}`; // Перенаправление на предыдущий проект
        } catch (error) {
            console.error('Error fetching previous project:', error);
        }
    };    

    useEffect(() => {
        if (slug) fetchProjectDetails();
    }, [slug]);

    const handleImageChange = (e) => {
        setNewImages(e.target.files);
    };

    const uploadNewImages = async () => {
        const formData = new FormData();
        Array.from(newImages).forEach(image => formData.append('images', image));

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/cards/${slug}/images`, {
                method: 'POST',
                body: formData
            });
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            await fetchProjectDetails();
            setNewImages([]);
        } catch (error) {
            console.error('Error uploading images:', error);
        }
    };

    if (!projectDetails) return <div>Loading...</div>;

    const images = (projectDetails.images || []).map((image) => ({
        original: `${process.env.REACT_APP_SERVER_URL}${image}`,
        thumbnail: `${process.env.REACT_APP_SERVER_URL}${image}`,
    }));

    return (
        <div>
            <div className="project-details">
                <div className="back-button-containerdev">
                <Link to="/" className="back-buttondev">
                    Вернуться на главную
                </Link>
                </div>
                <div className="main-image-container">
                    <img
                        src={`${process.env.REACT_APP_SERVER_URL}${projectDetails.image}`}
                        alt={projectDetails.title}
                        className="main-image-fullscreen"
                    />
                </div>
                <section className="project-description-container">
                    <h2 className="project-title"><MatrixText text={projectDetails.title}/></h2>
                    <p className="project-description">{projectDetails.description}</p>
                </section>

                {projectDetails.images && projectDetails.images.length > 0 && (
                    <div className="gallery">
                        <ImageGallery items={images} showThumbnails={true} />
                    </div>
                )}

                {isAdmin && (
                    <div className="add-images">
                        <input
                            type="file"
                            multiple
                            onChange={handleImageChange}
                            className="upload-input"
                        />
                        <button onClick={uploadNewImages} className="upload-button">Загрузить изображения</button>
                    </div>
                )}

                <footer className="navigation-buttons">
                    <button onClick={fetchPreviousProject} className="previous-project-button">Предыдущий проект</button>
                    <button onClick={fetchNextProject} className="next-project-button">Следующий проект</button>
                </footer>

            </div>
        </div>
    );
};

export default ProjectDetails;
