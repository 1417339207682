import React, { useState } from "react";
import MatrixText from "./MatrixText";
import TildaBackground from "./TildaBackground";
import "../styles/skil.css";
import { usePopup } from './PopupContext';

const Skills = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { showPopup } = usePopup();

  const services = [
    {
      id: 0,
      title: "Дизайн сайтов",
      price: "от 12 000 ₽",
      description: "Создание уникальных и привлекательных интерфейсов для вашего сайта.",
      link: "#website-design",
      linkText: "Заказать дизайн",
      particleColor: 0x00ff00,
      icons: [
        { alt: "Figma", src: "./image/figma.png" },
        { alt: "Adobe Illustrator", src: "./image/ilustrator.png" },
      ],
    },
    {
      id: 1,
      title: "Сайт под ключ",
      price: "от 18 000 ₽",
      description: "Полная разработка сайта с нуля до готового продукта.",
      link: "",
      linkText: "Заказать сайт под ключ",
      particleColor: 0x5d54e7,
      icons: [
        { alt: "JavaScript", src: "./image/java-script.png" },
        { alt: "React", src: "./image/react.png" },
        { alt: "Node", src: "./image/nodejs.png" },
      ],
    },
    {
      id: 2,
      title: "Брендинг под ключ",
      price: "от 70 000 ₽",
      description: "Комплексное создание бренда: логотип, фирменный стиль, SEO и многое другое.",
      link: "#branding",
      linkText: "Заказать брендинг",
      particleColor: 0x5d54e7,
      icons: [
        { alt: "Brand Strategy", src: "./image/brand.png" },
        { alt: "Logo Design", src: "./image/logo.png" },
      ],
    },
    {
      id: 3,
      title: "Сайты на Tilda",
      price: "от 12 000 ₽",
      description: "Создание современных и адаптивных сайтов на платформе Tilda.",
      link: "#tilda-sites",
      linkText: "Заказать сайт на Tilda",
      particleColor: 0x5d54e7,
      icons: [
        { alt: "Tilda", src: "./image/tilda.png" },
      ],
    },
  ];

  return (
    <section id="skil" className="content-skil" data-section="skil">
      <div className="skills-categories">
        {services.map((service) => (
          <div
            key={service.id}
            className="category-block"
            onMouseEnter={() => setHoveredIndex(service.id)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            {hoveredIndex === service.id && (
              <TildaBackground isHovered={true} particleColor={service.particleColor} />
            )}
            <div className="category-content">
              <div className="category-title">
                <div className="category-number">{`0${service.id + 1}`}</div>
                <MatrixText text={service.title} />
                <div className="category-price">{service.price}</div>
              </div>
              <div className="category-details">
                <p>{service.description}</p>
                <div className="service-icons">
                  {service.icons.map((icon, index) => (
                    <div key={index} className="icon-circle">
                      <img src={icon.src} alt={icon.alt} />
                    </div>
                  ))}
                </div>
                <a 
                  href={service.link} 
                  className="category-link" 
                  onClick={(event) => {
                    event.preventDefault();
                    showPopup(); 
                  }}
                >
                  {service.linkText}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
