import React, { useEffect, useState } from "react";
import "../styles/AdminDashboard.css";

const greetings = {
  maximka: {
    title: "Привет, Максимка!",
    message: "Кодишь как герой? Сегодня тебя ждет много работы, но я уверен, что ты справишься! 🚀",
  },
  natali: {
    title: "Здравствуй, Натали!",
    message: "Сегодня отличный день для творчества и дизайна. Вдохновляй и покоряй! 🎨",
  },
  default: {
    title: "Добро пожаловать!",
    message: "Программирование — это как приготовление кофе: сначала пишете код, потом отлаживаете его, а в конце понимаете, что забыли добавить главный ингредиент. 😅",
  },
};

function AdminDashboard() {
  const [username, setUsername] = useState(null);
  const [userGreeting, setUserGreeting] = useState(greetings.default);
  const [projectCount, setProjectCount] = useState(0);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("username");
    setUsername(loggedInUser);

    if (loggedInUser && greetings[loggedInUser]) {
      setUserGreeting(greetings[loggedInUser]);
    }

    const fetchProjects = async () => {
      try {
        let response;
        if (loggedInUser === "maximka") {
          response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/cards`);
        } else if (loggedInUser === "natali") {
          response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/designer-projects`);
        }
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        if (Array.isArray(data)) {
          setProjectCount(data.length);
        } else {
          console.error("Неверный формат данных:", data);
        }
      } catch (error) {
        console.error("Ошибка при загрузке проектов:", error.message);
      }
    };    

    fetchProjects();
  }, []);

  return (
    <div className="admin-dashboard-container">
      <h2 className="admin-dashboard-title">{userGreeting.title}</h2>
      <p className="admin-dashboard-message">{userGreeting.message}</p>
      <p className="admin-dashboard-projects">
        У вас {projectCount} {projectCount === 1 ? "проект" : projectCount < 5 ? "проекта" : "проектов"}.
      </p>
    </div>
  );
}

export default AdminDashboard;
