import * as THREE from "three";
import { useEffect, useRef } from "react";

const TildaBackground = ({ isHovered, particleColor }) => {
  const containerRef = useRef();

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, container.offsetWidth / container.offsetHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });

    renderer.setSize(container.offsetWidth, container.offsetHeight);
    container.appendChild(renderer.domElement);

    const particleCount = 500;
    const particlesGeometry = new THREE.BufferGeometry();
    const positions = [];

    for (let i = 0; i < particleCount; i++) {
      positions.push((Math.random() - 0.5) * 500, (Math.random() - 0.5) * 500, (Math.random() - 0.5) * 500);
    }

    particlesGeometry.setAttribute("position", new THREE.Float32BufferAttribute(positions, 4));
    const particlesMaterial = new THREE.PointsMaterial({
      color: particleColor, // Цвет частиц
      size: 2,
    });

    const particles = new THREE.Points(particlesGeometry, particlesMaterial);
    scene.add(particles);

    camera.position.z = 200;

    const animate = () => {
      if (isHovered) {
        particles.rotation.x += 0.002;
        particles.rotation.y += 0.002;
      }
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      renderer.dispose();
    };
  }, [isHovered, particleColor]);

  return (
    <div
      ref={containerRef}
      className="threejs-container"
      style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }}
    />
  );
};

export default TildaBackground;
