import React from "react";
import { Link } from "react-router-dom";
import '../styles/NotFound.css'; // Стили для 404 страницы

function NotFound() {
  const messages = [
    "Ты что-то сломал или это мы? В любом случае — 404!",
    "Как-то неловко получилось... Тут пусто. Может, пора на кофе-брейк?",
    "404 — это как баг, но без дебага.",
    "Ты зашел не туда. Но ведь и великие дизайны начинались с ошибок!",
    "Кажется, мы потеряли эту страницу. Хотя бы не макет в Figma!",
    "Ты сейчас на странице, которая не существует. Удивительное чувство, правда?",
    "Ты попал в 404 — место, где заканчиваются пути и начинается хаос.",
    "Упс! Наш сервер ушёл в отпуск, а эта страница не вернулась."
  ];

  const randomMessage = messages[Math.floor(Math.random() * messages.length)];

  return (
    <div className="not-found">
      <div className="not-found-content">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-message">{randomMessage}</p>
        <Link to="/" className="not-found-button">Вернуться на главную</Link>
      </div>
    </div>
  );
}

export default NotFound;
