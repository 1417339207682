import React, { createContext, useState, useContext } from 'react';

// Создаем контекст
const PopupContext = createContext();

// Хук для использования контекста
export const usePopup = () => useContext(PopupContext);

// Провайдер контекста
export const PopupProvider = ({ children }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => setPopupVisible((prev) => !prev);
  const showPopup = () => setPopupVisible(true);
  const hidePopup = () => setPopupVisible(false);

  return (
    <PopupContext.Provider value={{ isPopupVisible, togglePopup, showPopup, hidePopup }}>
      {children}
    </PopupContext.Provider>
  );
};
