import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'; // Импортируем Link
import '../styles/DesignerDetails.css';
import ImageGallery from 'react-image-gallery';
import MatrixText from "./MatrixText";
import ContactSection from './ContactSection';
import 'react-image-gallery/styles/css/image-gallery.css';

const DesignerDetails = () => {
    const { slug } = useParams();
    const [designerDetails, setDesignerDetails] = useState(null);
    const [newImages, setNewImages] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const adminStatus = localStorage.getItem('isAdmin') === 'true';
        setIsAdmin(adminStatus);
    }, []);

    const fetchDesignerDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/designer-projects/${slug}`);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();
            setDesignerDetails(data);
        } catch (error) {
            console.error('Error fetching designer details:', error);
        }
    };

    const fetchNextDesigner = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/designer-projects/${slug}/next`);
            if (!response.ok) {
                if (response.status === 404) {
                    const firstResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/designer-projects/first`);
                    if (!firstResponse.ok) throw new Error(`HTTP error! status: ${firstResponse.status}`);
                    const firstDesigner = await firstResponse.json();
                    window.location.href = `/designer/${firstDesigner.slug}`;
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } else {
                const nextDesigner = await response.json();
                window.location.href = `/designer/${nextDesigner.slug}`;
            }
        } catch (error) {
            console.error('Error fetching next designer:', error);
        }
    };
    
    const fetchPreviousDesigner = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/designer-projects/${slug}/previous`);
            if (!response.ok) {
                if (response.status === 404) {
                    const lastResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/designer-projects/last`);
                    if (!lastResponse.ok) throw new Error(`HTTP error! status: ${lastResponse.status}`);
                    const lastDesigner = await lastResponse.json();
                    window.location.href = `/designer/${lastDesigner.slug}`;
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } else {
                const previousDesigner = await response.json();
                window.location.href = `/designer/${previousDesigner.slug}`;
            }
        } catch (error) {
            console.error('Error fetching previous designer:', error);
        }
    };
    
    useEffect(() => {
        if (slug) fetchDesignerDetails();
    }, [slug]);

    const handleImageChange = (e) => {
        setNewImages(e.target.files);
    };

    const uploadNewImages = async (slug, files) => {
        if (!files || files.length === 0) {
            console.error('No files selected');
            return;
        }
    
        // Преобразуем FileList в массив
        const filesArray = Array.from(files);
    
        const formData = new FormData();
        filesArray.forEach((file) => formData.append('images', file));
    
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/designer-projects/${slug}/images`, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const { images: uploadedImages } = await response.json();
    
            console.log('Images uploaded successfully:', uploadedImages);
    
            // Обновляем массив изображений в `designerDetails`
            setDesignerDetails((prev) => ({
                ...prev,
                images: uploadedImages, // Используем весь массив изображений, включая старые и новые
            }));
    
            // Очищаем выбранные файлы
            setNewImages([]);
        } catch (error) {
            console.error('Error uploading images:', error);
        }
    };
    

    const deleteImage = async (slug, imagePath) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/designer-projects/${slug}/images`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ imagePath }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const { images: updatedImages } = await response.json();
    
            // Обновляем массив изображений
            setDesignerDetails((prev) => ({
                ...prev,
                images: updatedImages,
            }));
    
            console.log('Image deleted successfully');
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };
    
    
    if (!designerDetails) return <div>Loading...</div>;

    const images = Array.isArray(designerDetails.images)
    ? designerDetails.images.map((image) => ({
          original: `${process.env.REACT_APP_SERVER_URL}${image}`,
          thumbnail: `${process.env.REACT_APP_SERVER_URL}${image}`,
      }))
    : [];
    
    return (
        <div className="designer-container">
            {/* Кнопка "Вернуться на главную" */}
            <div className="back-button-container">
                <Link to="/designer" className="back-button">
                    Вернуться на главную
                </Link>
            </div>

            <div className="designer-header">
                <img
                    src={`${process.env.REACT_APP_SERVER_URL}${designerDetails.image}`}
                    alt={designerDetails.title}
                    className="designer-main-image"
                />
                <h2 className="designer-title">
                    <MatrixText text={designerDetails.title} />
                </h2>
            </div>

            <p className="designer-description">{designerDetails.description}</p>

            {designerDetails.images?.length > 0 && (
                <>
                    {/* Слайдер для отображения изображений */}
                    <ImageGallery
                        items={images}
                        showThumbnails
                        className="designer-gallery"
                    />

                    {/* Блок удаления изображений, доступный только администраторам */}
                    {isAdmin && (
                        <div className="admin-image-delete-container">
                            {designerDetails.images.map((image, index) => (
                                <div key={index} className="admin-image-delete-wrapper">
                                    <img
                                        src={`${process.env.REACT_APP_SERVER_URL}${image}`}
                                        alt={`Admin Image ${index}`}
                                        className="admin-gallery-image"
                                    />
                                    <button
                                        className="delete-button"
                                        onClick={() => deleteImage(designerDetails.slug, image)}
                                    >
                                        Удалить
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}



            {isAdmin && (
                <div className="designer-admin-panel">
                    <input
                        type="file"
                        multiple
                        onChange={handleImageChange}
                        className="designer-upload-input"
                    />
                    <button
                        onClick={() => uploadNewImages(slug, newImages)} // передаем newImages сюда
                        className="designer-upload-button"
                    >
                        Загрузить изображения
                    </button>
                </div>
            )}

            <div className="designer-navigation">
                <button
                    onClick={fetchPreviousDesigner}
                    className="designer-nav-button designer-prev"
                >
                    Предыдущий проект
                </button>
                <button
                    onClick={fetchNextDesigner}
                    className="designer-nav-button designer-next"
                >
                    Следующий проект
                </button>
            </div>

            <ContactSection />
        </div>
    );
};

export default DesignerDetails;
