import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MatrixText from "./MatrixText";
import '../styles/portfolio.css';

const Portfolio = () => {
    const [cards, setCards] = useState([]);
    const [activeCategory, setActiveCategory] = useState('Все');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [newCard, setNewCard] = useState({
        categories: [],
        title: "",
        description: "",
        image: null
    });

    const [isAdmin, setIsAdmin] = useState(false); // Состояние для хранения статуса авторизации

  useEffect(() => {
    // Проверяем статус авторизации при загрузке компонента
    const adminStatus = localStorage.getItem('isAdmin') === 'true';
    setIsAdmin(adminStatus);
    fetchCards("Все");
  }, []);

    const categories = [
        'Все',
        'Сайт с нуля',
        'Лендинг',
        'Tilda',
        'Многостраничный сайт',
        'React',
        'Vue'
    ];

    useEffect(() => {
        fetchCards("Все");
    }, []);

    const fetchCards = (category) => {
        setActiveCategory(category);
        setCards([]); // Сбрасываем карточки перед загрузкой
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/cards?category=${category}`)
            .then(response => response.json())
            .then(data => {
                const filteredCards = category === 'Все' ? data : data.filter(card => card.categories.includes(category));
                setCards(filteredCards);
            })
            .catch(error => console.error('Error fetching cards:', error));
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCard({
            ...newCard,
            [name]: value
        });
    };

    const handleCategoryChange = (category) => {
        setNewCard((prevCard) => {
            const categories = [...prevCard.categories];
            if (categories.includes(category)) {
                return { ...prevCard, categories: categories.filter(c => c !== category) };
            } else {
                return { ...prevCard, categories: [...categories, category] };
            }
        });
    };

    const handleFileChange = (e) => {
        setNewCard({
            ...newCard,
            image: e.target.files[0] // Save file
        });
    };

    const generateSlug = (title) => {
        return title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
      };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        const newSlug = generateSlug(newCard.title); // Генерация slug
        const newCardWithSlug = { ...newCard, slug: newSlug };

        formData.append("card", JSON.stringify(newCardWithSlug)); // Add card data
        formData.append("image", newCard.image); // Add file

        fetch(`${process.env.REACT_APP_SERVER_URL}/api/cards`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (response.ok) {
                    alert("Card added successfully!");
                    fetchCards(activeCategory);
                    setIsPopupOpen(false);
                    setNewCard({ categories: [], title: "", description: "", image: null });
                } else {
                    alert("Error adding card.");
                }
            })
            .catch(error => console.error('Error adding card:', error));
    };

    return (
        <section id="portfolio" className="content-section" data-section="portfolio">
            <div className="portfolio-header">
            <h2><MatrixText text="Наши проекты" /></h2>
                {isAdmin && (
                    <div className="add-card-button" onClick={() => setIsPopupOpen(true)}>+</div>
                    )}
            </div>

            <div className="portfolio-categories">
                {categories.map(category => (
                    <button
                        key={category}
                        className={`category-btn ${activeCategory === category ? 'active' : ''}`}
                        onClick={() => fetchCards(category)}
                    >
                        {category}
                    </button>
                ))}
            </div>

            <div className="portfolio-content">
                {cards.map((card) => (
                    <div className="portfolio-card" key={card.slug || card.id || card.title}>
                        <img src={`${process.env.REACT_APP_SERVER_URL}${card.image}`} alt={card.title} />
                        <h4>{card.title}</h4>
                        <p>{card.description}</p>
                        {card.slug ? <Link to={`/project/${card.slug}`}>Подробнее</Link> : <span>No Details</span>}
                    </div>
                ))}
            </div>
            

            {isPopupOpen && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <h3>Добавить новую карточку</h3>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="title"
                                placeholder="Название проекта"
                                value={newCard.title}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="text"
                                name="description"
                                placeholder="Описание"
                                value={newCard.description}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="file"
                                name="image"
                                onChange={handleFileChange}
                                required
                            />
                            <div className="category-selection">
                                <h4>Выберите категории</h4>
                                {categories.filter(c => c !== 'Все').map((category) => (
                                    <label key={category}>
                                        <input
                                            type="checkbox"
                                            checked={newCard.categories.includes(category)}
                                            onChange={() => handleCategoryChange(category)}
                                        />
                                        {category}
                                    </label>
                                ))}
                            </div>
                            <button type="submit">Добавить карточку</button>
                        </form>
                        <button className="close-popup" onClick={() => setIsPopupOpen(false)}>Закрыть</button>
                    </div>
                </div>
            )}
        </section>
    );
};

export default Portfolio;
