import React from "react";
import '../styles/home.css';
import { useEffect } from "react";
import Typed from "typed.js";

const Home = () => {
  // Предустановленные координаты и стили для 20 тегов
  const codeTags = [
    { tag: '<div>', top: '20%', left: '15%', opacity: 0.4, fontSize: '1.5rem' },
    { tag: '<h1>', top: '30%', left: '30%', opacity: 0.3, fontSize: '1.2rem' },
    { tag: '<section>', top: '25%', left: '60%', opacity: 0.5, fontSize: '1.6rem' },
    { tag: '<button>', top: '35%', left: '50%', opacity: 0.2, fontSize: '1rem' },
    { tag: '<span>', top: '45%', left: '75%', opacity: 0.3, fontSize: '1.8rem' },
    { tag: '<a>', top: '71%', left: '80%', opacity: 0.4, fontSize: '1.4rem' },
    { tag: '<p>', top: '70%', left: '25%', opacity: 0.2, fontSize: '1.1rem' },
    { tag: '<img>', top: '80%', left: '15%', opacity: 0.5, fontSize: '1.3rem' },
    { tag: '<nav>', top: '15%', left: '75%', opacity: 0.4, fontSize: '1.7rem' },
    { tag: '<footer>', top: '15%', left: '85%', opacity: 0.3, fontSize: '1.5rem' },
    { tag: '<header>', top: '35%', left: '10%', opacity: 0.2, fontSize: '1.2rem' },
    { tag: '<form>', top: '45%', left: '30%', opacity: 0.4, fontSize: '1.6rem' },
    { tag: '<ul>', top: '62%', left: '17%', opacity: 0.5, fontSize: '1.3rem' },
    { tag: '<li>', top: '84%', left: '42%', opacity: 0.3, fontSize: '1rem' },
    { tag: '<h2>', top: '75%', left: '60%', opacity: 0.2, fontSize: '1.8rem' },
    { tag: '<input>', top: '85%', left: '85%', opacity: 0.5, fontSize: '1.5rem' },
    { tag: '<textarea>', top: '15%', left: '40%', opacity: 0.4, fontSize: '1.1rem' },
    { tag: '<video>', top: '75%', left: '35%', opacity: 0.3, fontSize: '1.6rem' },
    { tag: '<article>', top: '32%', left: '70%', opacity: 0.2, fontSize: '1.7rem' },
    { tag: '<aside>', top: '55%', left: '90%', opacity: 0.4, fontSize: '1.3rem' }
  ];

  useEffect(() => {
    const typed = new Typed(".multiple-text", {
        strings: ["Продают", "Цепляют", "Вдохновляют"],
        typeSpeed: 50,
        backSpeed: 10,
        backDelay: 500,
        loop: true
    });

    return () => {
        typed.destroy(); // Удаляем инстанс Typed.js при размонтировании
    };
}, []);

  return (
    <section id="home" className="home" data-section="home">
      {/* Фоновые элементы */}
      <div className="background-layer layer1">
        {codeTags.map((tag, index) => (
          <span
            key={index}
            className="code-tag"
            style={{
              top: tag.top,
              left: tag.left,
              opacity: tag.opacity,
              fontSize: tag.fontSize
            }}
          >
            {tag.tag}
          </span>
        ))}
      </div>

      {/* Яркие продающие элементы с рамками "h2" */}
      <div className="highlighted-elements">
        <h2 className="highlighted-code one" >Быстро</h2>
        <h2 className="highlighted-code two" >Надежно</h2>
        <h2 className="highlighted-code three" >Эффективно</h2>
      </div>

      {/* Заголовок */}
      <div className="home-content">
        <h1 className="custom-heading">
          <span className="line1">СОЗДАЕМ</span>
          <div className="cent">
            <span className="line2">САЙТЫ</span>
            <h2 className="mult">
              <h2> которые </h2>
              <h2><span className="multiple-text" style={{}}></span></h2>
            </h2>
          </div>
          
        </h1>
        
      </div>
    </section>
  );
};

export default Home;
