import React, { useState, useEffect } from 'react';
import { usePopup } from './PopupContext';
import '../styles/Header.css';

const Header = () => {
  const { isPopupVisible, togglePopup } = usePopup();
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    comment: '',
  });
  const [errors, setErrors] = useState({});
  const [currentView, setCurrentView] = useState("form"); // 'about' или 'form'
  const [isSmallScreen, setIsSmallScreen] = useState(false);

   // Обновляем состояние в зависимости от ширины экрана
   useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1000);
    };

    handleResize(); // Установить начальное состояние
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Получаем текущий путь страницы
  const isHomePage = window.location.pathname === '/';

  const toggleView = () => {
    setCurrentView((prev) => (prev === "about" ? "form" : "about"));
  };

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
    if (!isMenuVisible) {
      // Блокируем прокрутку, когда меню открыто
      document.body.style.overflow = 'hidden';
    } else {
      // Восстанавливаем прокрутку, когда меню закрыто
      document.body.style.overflow = '';
    }
  };
  
  // Блокировка/разблокировка фонового скроллинга
  useEffect(() => {
    if (isPopupVisible) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isPopupVisible]);

  // Обработка изменения данных формы
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Валидация формы
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Поле обязательно для заполнения';
    if (!formData.email) newErrors.email = 'Поле обязательно для заполнения';
    if (!formData.comment) newErrors.comment = 'Поле обязательно для заполнения';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Отправка формы
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    // Отправка запроса на сервер через API
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/submit-form`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert('Заявка отправлена');
      setFormData({ name: '', email: '', comment: '' }); // Очистить форму
    } else {
      alert('Произошла ошибка при отправке заявки');
    }
  };

  return (
    <>
       <header className="header">
        <a href="/" className="logo hed">
          <img src="/image/log.svg" alt="Logo" />
        </a>

        <nav className={`navbar ${isMenuVisible ? 'visible' : ''}`}>
          {/* Динамическое изменение ссылок для десктопа */}
          {/* <a href={isHomePage ? "#home" : "/#home"} data-target="home" className="active">
            Главная
          </a> */}
          <a href={isHomePage ? "#skil" : "/#skil"} data-target="skil">
            Услуги
          </a>
          <a href={isHomePage ? "#portfolio" : "/#portfolio"} data-target="portfolio">
            Портфолио
          </a>
          <a href="/designer">
            Дизайнер
          </a>
          <button className="btn request-btn" onClick={togglePopup}>
            Оставить заявку
            <img src="/image/hat.png" alt="Новогодняя шапка" className="christmas-hat" />
          </button>
        </nav>

        <nav className={`full-screen-menu ${isMenuVisible ? 'visible' : ''}`}>
          <button className="close-menu-btn" onClick={toggleMenu}>
            &times;
          </button>
          <ul>
            <li>
              <a href={isHomePage ? "#home" : "/#home"} data-target="home" className="active" onClick={toggleMenu}>
                Главная
              </a>
            </li>
            <li>
              <a href={isHomePage ? "#skil" : "/#skil"} data-target="skil" onClick={toggleMenu}>
                Услуги
              </a>
            </li>
            <li>
              <a href={isHomePage ? "#portfolio" : "/#portfolio"} data-target="portfolio" onClick={toggleMenu}>
                Портфолио
              </a>
            </li>
            <li>
              <a href="/designer" onClick={toggleMenu}>
                Страничка дизайнера
              </a>
            </li>
            <li>
              <button className="btn request-btn" onClick={() => { togglePopup(); toggleMenu(); }}>
                Оставить заявку
                <img src="./image/hat.png" alt="Новогодняя шапка" className="christmas-hat" />
              </button>
            </li>
          </ul>
        </nav>

        <div className="Burger" id="burger-menu" onClick={toggleMenu}>
          <span></span>
        </div>
      </header>

      {isPopupVisible && (
        <div className="popup-overlay" onClick={togglePopup}>
          <div className="popup-container" onClick={(e) => e.stopPropagation()}>
            <span className="close-btn" onClick={togglePopup}>
              &times;
            </span>
            <div className="popup-contents">
             {(!isSmallScreen || currentView === "about") && (
              <div className="popup-half left-half">
                <h2>О нас</h2>
                <p>
                  Мы — про гениальность в каждом пикселе. 
                  Наш <span style={{ color: '#4d46b8' }}>фуллстек-разработчик</span> пишет код, который реально работает, а <span style={{ color: '#30B100' }}>Дизайнер</span> создаёт интерфейсы, с которыми пользователь не захочет расставаться. 
                  Вместе мы создаём проекты, которые не просто выглядят круто, но и помогают нашим клиентам достигать реальных успехов.
                </p>

                <p className="NameM">Максим - Веб разработчик</p>
                <div className="social-medias">
                  <a href="mailto:makswarpten@mail.ru" target="_blank" rel="noopener noreferrer">
                    <i className="bx bx-envelope"></i>
                  </a>
                  <a href="https://t.me/Snamik" target="_blank" rel="noopener noreferrer">
                    <i className="bx bxl-telegram"></i>
                  </a>
                  <a href="https://github.com/SnamikMT" target="_blank" rel="noopener noreferrer">
                    <i className="bx bxl-github"></i>
                  </a>
                  <a href="https://www.instagram.com/snamik_?igsh=cW9zdW93dG9va2ts" target="_blank" rel="noopener noreferrer">
                    <i className="bx bxl-instagram"></i>
                  </a>
                </div>
                <p className="NameN">Натали - Дизайнер</p>
                <div className="social-medias neich">
                  <a href="https://t.me/revuls1on" target="_blank" rel="noopener noreferrer">
                    <i className="bx bxl-telegram"></i>
                  </a>
                  <a href="https://www.instagram.com/rev.ulsion?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer">
                    <i className="bx bxl-instagram"></i>
                  </a>
                  <a href="https://www.youtube.com/@rev.ulsion" target="_blank" rel="noopener noreferrer">
                    <i className="bx bxl-youtube"></i>
                  </a>
                </div>
                {isSmallScreen && (
                 <button className="toggle-view-btn about" onClick={toggleView}>
                  {currentView === "about" ? "Перейти к заявке" : "О нас"}
                  <i className="bx bx-right-arrow-alt"></i>
                </button>
               )}
              </div>
              )}

              {(!isSmallScreen || currentView === "form") && (
              <div className="popup-half right-half">
                <h2>Оставить заявку</h2>
                <form onSubmit={handleSubmit}>
                  <span className="required">*</span>
                  <div className="input-container">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      className="custom-holder"
                      onChange={handleChange}
                      placeholder="Ваше имя"
                    />
                    {errors.name && <span className="error">{errors.name}</span>}
                    
                  </div>
                  <span className="required">*</span>
                  <div className="input-container">
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      className="custom-holder"
                      onChange={handleChange}
                      placeholder="Ваш email"
                    />
                    {errors.email && <span className="error">{errors.email}</span>}
                    
                  </div>
                  <span className="required">*</span>
                  <div className="input-container">
                    <input
                      type="text"
                      name="comment"
                      value={formData.comment}
                      onChange={handleChange}
                      className="custom-holder"
                      placeholder="Комментарий"
                    />
                    {errors.comment && <span className="error">{errors.comment}</span>}
                    
                  </div>
                  <button type="submit" className='sub'>Отправить</button>
                  <p className='politic'>
                  Нажимая кнопку «<span>Отправить</span>» Вы соглашаетесь на обработку предоставленных вами персональных данных.
                </p>
                </form>
                {isSmallScreen && (
                  <button className="toggle-view-btn" onClick={toggleView}>
                    {currentView === "about" ? "Перейти к заявке" : "Узнать о нас"}
                    <i className="bx bx-right-arrow-alt"></i>
                  </button>
                )}
              </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;